
.Z-index-buffer {
    position: relative;
    z-index: 1;
}

.Avatar-outer {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    box-shadow: inset 1px 2px 8px #00000044;
}

@keyframes Shimmer {
    0% {
        background-position: 100%;
    }
    100% {
        background-position: -100%;
    }
}

.Avatar {
    z-index: -1;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: block;
    background-color: #dcd6d3;
    object-fit: cover;
}

.Avatar.Loading {
    animation: Shimmer linear 1s infinite;
    background: #dcd6d3;
    background: var(--loading-gradient);
    background-size: 200% 200%;
}