.Feed-forum {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 18pt;
    padding: 16px 0px;
    text-decoration: none;
    align-items: center;
    border-bottom: 1px solid #bdb2aa;
}

.Feed-forum-image {
    position: relative;
    z-index: -1;
    display: block;
    border-radius: 100%;
    width: 52pt;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    background-color: #dcd6d3;
}

.Feed-forum-main {
    flex-grow: 1;
    align-self: baseline;
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 4pt;
    min-width: 0;
    font-family: 'Quicksand', sans-serif;
}

.No-underline {
    text-decoration: none;
}

.Feed-forum-title {
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;  
overflow: hidden;
}

.Feed-forum-description {
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    color: #6A625C;
    font-size: 12pt;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    min-width: 0;
}