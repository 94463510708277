.Comment {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 16px 0 8px 0;
    text-decoration: none;
}

.Comment-wrap-container {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    min-width: 0;
    gap: 18pt;
}

.Comment-center {
    flex-grow: 1;
    align-self: baseline;
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 4pt;
    min-width: 0;
}

.Comment-avatar {
    width: 38px;
    margin-top: 4px;
}

.Comment-content {
    color: var(--text-color);
    min-width: 0;
}
.Comment-author {
    text-decoration: none;
    font-weight: 400;
    color: #6A625C;
    font-size: 10pt;
    white-space: nowrap;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Comment-reply-button {
    border-radius: 50%;
    border: none;
    background: none;
    padding: 4px;
    color: #bdb2aa;
    align-self: flex-start;
}

.Comment-reply-button:hover {
    background-color: #e6e6e6;
}

svg.Comment-reply-icon {
    display: block;
    width: 20pt;
    height: 20pt;
}

.Comment-flex-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 4pt;
}

.Comment-nesting-container {
    display: flex;
    align-content: stretch;
    gap: 4px;
    padding-bottom: 4px;
}
.Comment-replies {
    flex-grow: 1;
    min-width: 0;
}

.Reply-buttons {
    display: flex;
    gap: 8px;
    width: auto;
    justify-content: flex-end;
}

.Reply-input {
    font-family: 'Open Sans', sans-serif;
    color: var(--text-color);
    font-size: 13pt;
    background-color: #f1f1f1;
    padding: 4px 8px;
    border-radius: 12px;
    border: solid 3px #d0cecc;
    transition: border 0.3s;
    height: 6em;
    width: 100%;
    resize: none;
}

.Reply-input:focus-visible {
    border-color: var(--accent-color);
    outline: none;
}

.hide {
    display: none;
}

.Comment-nesting-bar {
    width: 2px;
    background-color: #d0cecc;
    flex-grow: 1;
}

.Comment-visibility-button {
    border: none;
    border-radius: 16px;
    background: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 12px 0 4px;
}
.Comment-visibility-button:hover {
    background-color: #e6e6e6;
}

.Comment-visibility-button.Vertical {
    flex-direction: column;
    padding: 0 0 8px 0;
}

.Comment-visibility-icon {
    color: #bdb2aa;
    margin: -2px;
}