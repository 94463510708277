@media (max-width:500px) {
    .Navbar-username, .Title {
        display: none;
    }

    div.Nav-dropdown-button {
        border: none;
        display: none;
    }
    div.Nav-dropdown-button-narrow {
        display: flex;
    }
}

.Nav {
    background-color: #f1f1f1;
    width: 100vw;
    height: calc(38pt + 16px + 6px); /* Drodowns + padding + border */
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 6px solid;
    border-image: var(--accent-gradient) 1;
    padding: 8px 32px;
    box-shadow: 0px 4px 4px rgba(62, 50, 44, 0.25);
    position: fixed;
    z-index: 999;
    gap :12px;
}

.App-logo {
    height: 36pt;
    width: 36pt;
    margin-right: 1pt;
}

.Site-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
}

.Title {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 34pt;
    color: var(--accent-color);
    white-space: nowrap;
    margin-bottom: -2pt;
    line-height: 1em;
}

.Join-link {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 18pt;
    color: var(--text-color);
    text-decoration: none;
    margin: 0 8pt;
    white-space: nowrap;
  }

.Join-link:hover {
    color: #6A625C;
    text-decoration: underline;
    text-decoration-thickness: 2px;
}

.Navbar-username {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 18pt;
    color: var(--text-color);
    align-self: center;
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Navbar-avatar {
    height: 38pt;
    width: 38pt;
    flex-shrink: 0;
}

.Profile-dropdown-button {
    padding: 0 8px;
    min-width: 0;
    user-select: none;
    cursor: pointer;
}

.Profile-dropdown-button:hover {
    border-radius: 19pt;
    background-color: #6A625C33;
}

.Profile-dropdown {
    position: absolute;
    top: 10pt;
    right: 4pt;
    background-color: #f1f1f1;
    border-radius: 0.25em;
    box-shadow: 2px 4px 8px 3px rgba(62, 50, 44, 0.20);
    padding: 8px 0;
    max-height: calc(100vh - 32px - 42pt);
    overflow-y: auto;
}

.Nav-dropdown-button {
    padding: 0 10px 0 16px;
    border-radius: 19pt;
    border: solid 3px #d0cecc;
    height: 38pt;
    align-items: center;
    user-select: none;
    cursor: pointer;
}
.Nav-dropdown-container {
    flex-shrink: 0;
}

.Nav-dropdown-button-narrow {
    display: none;
    border-radius: 19pt;
    height: 38pt;
    width: 38pt;
    user-select: none;
    cursor: pointer;
    color:#6A625C;
    align-items: center;
    justify-content: center;
}

.Profile-dropdown-button:hover, .Nav-dropdown-button:hover, .Nav-dropdown-button-narrow:hover {
    background-color: #6A625C33;
}
.Nav-dropdown {
    position: absolute;
    top: 10pt;
    left: 4pt;
    background-color: #f1f1f1;
    border-radius: 0.25em;
    box-shadow: 2px 4px 8px 3px rgba(62, 50, 44, 0.20);
    padding: 8px 0;
    min-width: 0;
    max-height: calc(100vh - 32px - 42pt);
    overflow-y: auto;
}
.Nav-dropdown-item {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 16pt;
    color: #6A625C;
    text-decoration: none;
    padding: 4px 24pt 4px 12px;
    width: min(65vw, 30ch);
    white-space: nowrap;
    border: none;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 12px;
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
}

.Nav-dropdown-icon {
    display: block;
    width: 22pt;
    height: 22pt;
    object-fit: cover;
    border-radius: 50%;
    flex-shrink: 0;
    background-color: #dcd6d3;
}

.Profile-dropdown-item {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 16pt;
    color: #6A625C;
    text-decoration: none;
    padding: 4px 48pt 4px 12px;
    width: 100%;
    white-space: nowrap;
    border: none;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    user-select: none;
}
/* Must be svg.class to have higher specificity than MUI builtin styling */
svg.Profile-dropdown-icon {
    display: block;
    color: #6A625CCC;
    width: 22pt;
    height: 22pt;
}

.Profile-dropdown-item:hover, .Nav-dropdown-item:hover {
    background-color: #6A625C33;
}

/* Reduce margins on the separator in the dropdown */
.Profile-dropdown .Separator {
    margin: 8px 0;
}