.Dropdown-root {
    position: relative;
    display: block;
    min-width: 0;
}

.Dropdown-menu {
    position: absolute;
    width: 100%;
    transition-property: opacity, transform, visibility;
    transition-duration: 0.25s;
    transition-timing-function: ease-in-out;
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}

.Dropdown-menu.Closed {
    opacity: 0;
    transform: translateY(-10pt);
    visibility: hidden;
}