* {
  box-sizing: border-box;
  margin: 0;
}

:root {
  --background-color: #e1d5ce;
  --text-color: #212121;
  --accent-color: #B90E31;
  --accent-color-secondary: #B9630E;
  --accent-gradient: linear-gradient(270deg, var(--accent-color) 23.96%, #B9630E 100%);;
  --loading-gradient: linear-gradient(90deg, #dcd6d3 10%, #ece8e5 30%, #dcd6d3 50%);
}

@media (max-width:768px) {
  .App > main {
    flex-direction: column-reverse;
    justify-content: stretch;
    align-items: center;
  }

  main > .Feed, main > .Landing, main > .Signin {
    width: 100%;
    flex-grow: 1;
    padding-left: 0.5em;
    padding-right: 0.3em;
  }

  main > .Sidebar {
    width: 100%;
    border-radius: 1em;
  }

  div.Feed-header {
    padding-left: 0;
    padding-right: 0;
  }
}

@keyframes Shimmer {
  0% {
      background-position: 100%;
  }
  100% {
      background-position: -100%;
  }
}

.App {
  background-color: var(--background-color);
  min-height: 100vh;
  /*font-size: calc(10px + 2vmin);*/
  font-size: 18pt;
  text-align: center;
  color: var(--text-color);
}

.Link {
  font-family: 'Open Sans', sans-serif;
  color: var(--accent-color);
  font-size: 13pt;
}

.Link:hover {
  color: var(--accent-color-secondary);
}

h2 {
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  font-size: 22pt;
  color: var(--text-color);
  text-align: left;
  word-wrap: break-word;
  margin-bottom: 8px;
  line-height: 1.25em;
}
.Loading-header {
  margin: 8px;
  width: 75%;
  height: 20pt;
  border-radius: 10pt;
  animation: Shimmer linear 1s infinite;
  background: var(--loading-gradient);
  background-size: 200% 200%;
  /* Prevent margin collapse so spacing looks nice */
  display: inline;
  float: left;
}

h3 {
  font-family: 'Quicksand', sans-serif;
  font-size: 16pt;
  word-wrap: break-word;
  color: var(--text-color);
}

p {
  font-family: 'Open Sans', sans-serif;
  color: var(--text-color);
  font-size: 13pt;
  text-align: left;
  word-wrap: break-word;
  white-space: pre-line;
  color: #6A625C;
}

main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 18pt;
  padding: calc(76px + 1em) 12px 0 12px;
  min-height: 100vh;
}

.Feed {
  background-color: #f1f1f1;
  width: min(72ch, 66%);
  min-height: 100%;
  border-radius: 1em 1em 0 0;
  box-shadow: 0px 4px 4px rgba(62, 50, 44, 0.25);
  padding: 0 1em 3em;
}
.Feed.Pad-top {
  padding-top: 1em;
}

.Feed-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 1em 0 0 ;
  border-bottom: 1px solid #bdb2aa;
}

.Feed-header-button {
  padding: 14pt 8pt 4pt;
  background: none;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 16pt;
  color: #6A625C;
  border: none;
  cursor: pointer;
  border-bottom: 6px solid transparent; /* Prevent shifting when selected*/
}
.Feed-header-button:hover:enabled {
  color: var(--text-color);
  background-color: #d6cdc6;
}
.Feed-header-button:disabled {
  color: #88827e;
  cursor: unset;
}

.Feed-header-button.Selected {
  color: var(--text-color);
  border-bottom: 6px solid;
  border-image: var(--accent-gradient) 1;
}

.Sidebar {
  background-color: #f1f1f1;
  width: min(32ch, 33%);
  min-width: 20ch;
  min-height: 100%;
  border-radius: 1em 1em 0 0;
  box-shadow: 0px 4px 4px rgba(62, 50, 44, 0.25);
  padding: 12px;
}
.Sidebar > p {
  margin: 12px 0px;
}

.Sidebar-banner {
  width: 100%;
  aspect-ratio: 7 / 4;
  object-fit: cover;
  border-radius: calc(1em - 12px);
  margin-bottom: 1em;
  position: relative;
  display: block;
  background: #dcd6d3;
}
.Sidebar-banner.Loading {
  animation: Shimmer linear 1s infinite;
  background: var(--loading-gradient);
  background-size: 200% 200%;
}

.Sidebar-banner-container {
  position: relative;
}

.Sidebar-banner-overlay {
  position: absolute;
  left: 1.5em;
  bottom: 0px;
}
.Sidebar-avatar {
  height: calc(4em + 12px);
  width: calc(4em + 12px);
  border-radius: 50%;
  border: 6px solid #f1f1f1
}

.Sidebar-banner-upload {
  position: absolute;
  width: 100%;
  aspect-ratio: 7 / 4;
  border-radius: calc(1em - 12px);
  background-color: #21212166;
  top: 0;
  left: 0;
  opacity: 0;
}
.Avatar-upload {
  position: absolute;
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background-color: #21212166;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
}

.Sidebar-banner-upload:hover, .Avatar-upload:hover {
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Sidebar-banner-upload:hover svg, .Avatar-upload:hover svg {
  color: #f1f1f1;
  font-size: 36pt;
}

.Spacer {
  flex-grow: 1;
}

.Separator {
  margin: 16px 0;
  width: 100%;
  border-bottom: 2px solid #d0cecc;
}

.Signin {
  background-color: #f1f1f1;
  width: min(48ch, 95%);
  border-radius: 1em 1em 0 0;
  padding: 12pt 0;
}

.Center-text {
  text-align: center;
}

#Login-form {
  width: min(40ch, 100%);
  text-align: left;
  margin: auto;
  padding: 4pt;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 20px;
}

.Input-box {
  font-family: 'Open Sans', sans-serif;
  color: var(--text-color);
  font-size: 14pt;
  background-color: #f1f1f1;
  margin: 0;
  padding: 4px 8px;
  border-radius: 12px;
  border: solid 3px #d0cecc;
  transition: border 0.3s;
  height: 2.25em;
  min-width: 200px;
}

.Input-box:focus-visible {
  border-color: var(--accent-color);
  outline: none;
}

input[type="checkbox"] {
  margin-right: 12px;
}

.Input-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
}

.Input-row > * {
  flex: 1 1 150px;
}
#Program-input {
  flex-grow: 4;
}

.Checkbox-label {
  font-family: 'Open Sans', sans-serif;
  color: var(--text-color);
  font-size: 13pt;
  text-align: left;
}

.Login-error {
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 18pt;
  color: #890E1a;
}

.Password-container {
  position: relative;
  align-items: center;
}

#Password {
  padding-right: 30pt;
}
.Password-reveal {
  position: absolute;
  color: #6A625Cdd;
  border: none;
  background: none;
  right: 4pt;
  cursor: pointer;
}
.Password-reveal:hover {
  color: #6A625C99
}
.Password-reveal > svg {
  display: block;
}

.Flex-row {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

#Profile-update-form, #Password-change-form {
  max-width: 60ch;
}
.Label-and-input {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}
.Label-and-input > p {
  margin: 12px 0 4px 0;
}

textarea.Input-box {
  resize: none;
  height: 12em;
}

.Character-counter {
  font-size: 10pt;
  text-align: right;
}

.Comment-bar {
  border-top: 2px solid #d0cecc;
  border-bottom: 2px solid #d0cecc;
  padding: 0 12px;
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Comments-label {
  color: #6A625C;
}

.End-notice {
  padding: 1em;
  color: #6A625C;
}