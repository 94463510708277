.showUp {
    background-color: white;
    z-index: 1000;
    width: 400px;
    height: 400px;
}

#CreatePost {
    flex-wrap: wrap;
}

.CreatePostHeader{
    padding:20px;
    margin-bottom: 0;
}

select.forumsDropdown {
    border-radius: 25px;
    padding: 9px 34px 9px 20px;
    transition: 0.3s;
    cursor: pointer;
    line-height: 38pt;
    height: 38pt;
}

.forum-option {
    border-radius: 20px;
    width: 50px
}



.forumsDropdown h2 {
    font-size: 20px;
    margin:0;
}

.Create-post-div {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

#CreatePost {
    display: flex;
    align-items: center;
    padding:10px;
}

#Create-post-form {
    display: flex;
    flex-direction: column;
    gap:25px;
    margin: 0;
    justify-content: center;
    align-items: center;
    width: 700px;
    align-self: center;
}

#Create-post-form input {
    width: 100%;
}

.Create-post-body {
    height: 300px;
    width: 100%;
}

.forumsDropdown svg {
    width: 40px;
    height: 40px;
}
