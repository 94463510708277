
@media (max-width:640px) {
    div.Feed-post {
        flex-direction: column;
        align-items: stretch;
        gap: 10pt;
    }
  
    div.Feed-post-stats {
        flex-direction: row;
        gap: 18px;
    }

    div.Feed-post-avatar {
        width: 36pt;
        height: 36pt;
    }
}

.Feed-post {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 18pt;
    padding: 16px 0px;
    text-decoration: none;
    align-items: center;
    border-bottom: 1px solid #bdb2aa;
}

.Feed-post-wrap-container {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    min-width: 0;
    gap: 18pt;
}

.Feed-post-avatar {
    width: 48pt;
    height: 48pt;
}

.Feed-post-center {
    flex-grow: 1;
    align-self: baseline;
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 4pt;
    min-width: 0;
    font-family: 'Quicksand', sans-serif;
}

.No-underline {
    text-decoration: none;
}

.Feed-post-author, .Feed-post-author > a {
    text-decoration: none;
    font-weight: 400;
    color: #6A625C;
    font-size: 10pt;
}

.Feed-post-title {
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;  
overflow: hidden;
}

.Feed-post-content {
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    color: #6A625C;
    font-size: 12pt;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    min-width: 0;
}

.Feed-post-stats {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 2px;
}

.Feed-post-stat {
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    color: #6A625C;
    font-size: 10pt;
    white-space: nowrap;
    line-height: 1.5rem; /* Match icon height to improve baseline alignment */
}

.Stat-icon {
    color: #8c8681;
    vertical-align: middle;
    margin-right: 10px;
}

.Time-ago {
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    color: #6A625C;
    font-size: 10pt;
    line-height: 1.5rem; /* Match icon height to improve baseline alignment */
    white-space: nowrap;
}

.Vote-arrows {
    display: grid;
}

.Vote-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}

.Vote-counter {
    font-size: 12pt;
    flex-grow: 1;
    text-align: center;
}

.Like-button svg {
    display: block;
    cursor: pointer;
    color: #6A625C;
}

.Liked svg, p.Liked {
    color: #dd730b;
}

.Disliked svg, p.Disliked {
    color: #B90E31;
}
