.Cta-button {
    color: #f1f1f1;
    background: var(--accent-gradient);
    border-radius: 2em;
    min-width: 120px;
    margin: 8pt 0;
    padding: 3pt 16pt;
    border: 2px solid #6F051B66;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: flex-start;
}
.Cta-button.Small {
    min-width: 80px;
    margin: 8pt 0;
    padding: 2pt 8pt;
}

.Cta-button-text {
    color: #f1f1f1;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 16pt;
    margin: 0;
}
.Cta-button.Small .Cta-button-text {
    font-size: 14pt;
}

.Cta-button:disabled {
    background: #88827e;
    border-color: #21212166;
    cursor: revert;
    pointer-events: none;
}

.Cta-button.Outlined {
    color: var(--accent-color);
    background: transparent;
    position: relative;
    border-color: transparent;
}
.Cta-button.Outlined p {
    background: var(--accent-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
.Cta-button.Outlined::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 2em;
  padding: 2px;
  margin: -2px;
  background: var(--accent-gradient); 
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
          mask:
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
}
.Cta-button.Outlined:disabled::before {
    background: #88827e;
    border-color: #88827e;
}
.Cta-button.Outlined:disabled {
    color: #88827e;
}
.Cta-button.Outlined:disabled p {
    background: #88827e;
    -webkit-background-clip: text;
    background-clip: text;
}

.Cta-button svg {
    width: 21pt;
    height: 21pt;
    display: block;
}

.Cta-button.Small svg {
    width: 18pt;
    height: 18pt;
}