.Create-forum-header {
    padding:20px;
    padding-bottom: 10px;
    margin-bottom: 0;
}

.Create-forum-div {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

#CreatePost {
    display: flex;
    align-items: center;
    padding:10px;
}

#Create-forum-form {
    display: flex;
    flex-direction: column;
    gap:25px;
    margin: 0;
    justify-content: center;
    align-items: center;
    width: 700px;
    align-self: center;
}

#Create-forum-form input {
    width: 100%;
}

.Create-forum-bio {
    height: 300px;
    width: 100%;
}

.forumsDropdown svg {
    width: 40px;
    height: 40px;
}
